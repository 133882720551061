<template>
  <v-app
    id="login"
    class="secondary"
  >
    <v-content class="grad1">
      <v-container fluid>
        <v-row>
          <v-col>
            <v-layout
              align-center
              justify-center
            >
              <img
                src="@/assets/pumas-brand-header.svg"
                alt="Vue Material Admin"
                width="180"
                height="180"
              >
            </v-layout>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <v-row>
          <v-col
            sm="12"
            lg="6"
          >
            <v-layout
              align-center
              justify-center
            >
              <v-img
                lazy-src="@/assets/slider_3.svg"
                src="@/assets/slider_3.svg"
              />
            </v-layout>
          </v-col>
          <v-col
            sm="12"
            lg="5"
          >
            <v-layout
              align-center
              justify-center
              class="text-center"
            >
              <v-flex xs10>
                <div class="layout column align-center">
                  <h2>Lyv</h2>
                  <h4 class="flex my-4 theme--text">
                    Precision Healthcare
                  </h4>
                </div>
                <v-form
                  ref="form"
                  lazy-validation
                >
                  <v-text-field
                    v-model="email"
                    append-icon="person"
                    name="login"
                    label="Email"
                    type="text"
                    :error="error"
                    :rules="emailRules"
                    outlined
                  />
                  <v-text-field
                    id="password"
                    v-model="password"
                    :type="hidePassword ? 'password' : 'text'"
                    :append-icon="
                      hidePassword ? 'visibility_off' : 'visibility'
                    "
                    name="password"
                    label="Password"
                    :rules="passwordRule"
                    :error="error"
                    outlined
                    @click:append="hidePassword = !hidePassword"
                  />
                  <router-link to="/auth/forgot-password">
                    Forgot Password?
                  </router-link><br>
                </v-form>
                <br>

                <v-btn
                  class="theme--white"
                  color="#EF515B"
                  :loading="loading"
                  @click="login"
                >
                  Login
                </v-btn>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar
        v-model="ui.infoSnackbar.state"
        top
        :timeout="ui.infoSnackbar.timeout || 6000"
        :color="ui.infoSnackbar.color"
      >
        {{ ui.infoSnackbar.info }}
      </v-snackbar>
    </v-content>
    <Snackbar />
  </v-app>
</template>

<script>
import Snackbar from "../../components/ui/Snackbar";
import { mapMutations } from "vuex";
import constant from "../../config/constant"
import Error_Message from "@/utils/notification";
export default {
  name: "Login",
  components: {
    Snackbar,
  },
  data() {
    return {
      loading: false,
      email: "",
      password: "",
      hidePassword: true,
      error: false,
      showResult: false,
      result: "",
      emailRules: [
        (v) => !!v || constant.forgotPassword.content,
        (v) => /.+@.+\..+/.test(v) || constant.forgotPassword.content1,
      ],
      passwordRule: [(v) => !!v || constant.changePassword.content],
      ui: {
        retrieveLoading: false,
        infoSnackbar: {
          state: false,
          color: "error",
          info: null,
          timeout: 6000,
        },
      },
    };
  },
  mounted() {
    this.ui.retrieveLoading = true;
    if (this.$store.getters.loggedIn) {
      this.$router.push({ name: "Condition" });
    }
  },

  methods: {
    login() {
      // this.$refs.form.validate()
      const vm = this;
      if (!vm.email || !vm.password) {
        this.setSnack(constant.forgotPassword.content_cred);
        return;
      }
      this.ui.retrieveLoading = true;
      const { email, password } = this;
      this.$store
        .dispatch("login", {
          email: email,
          password: password,
        })
        .then((res) => {
          this.ui.retrieveLoading = false;
          if (res.data.code == 1001) {
            this.setSnack(res.data.message);
            this.$router.push({ name: "changePassword" });
          } else if (res.data.code == 1002) {
            this.$router.push({ name: "acceptedTermsAndCondition" });
          } else {
            this.$router.push({ name: "Condition" });
          }
        })
        .catch((error) => {
          // this.setSnack(error.data.message);
          this.$store.commit(
            "snackbar/setSnack",
            Error_Message.display_message(error.data.message)
          );
          
        });
    },
    ...mapMutations({
      setSnack: "snackbar/setSnack",
    }),
  },
};
</script>

<style scoped lang="scss">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
.grad1 {
  background: linear-gradient(120deg, #c2c4c5, #c2c4c5, #ebb9a9 85%);
  background-size: 200% 200%;
  -webkit-animation: Animation 5s ease infinite;
  -moz-animation: Animation 5s ease infinite;
  animation: Animation 5s ease infinite;
}
@-webkit-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@-moz-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
v-h1 {
  font-size: 5rem;
  line-height: 3.65rem;
  margin-bottom: 4rem;
  margin-top: 4rem;
}
</style>
