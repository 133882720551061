<template>
  <v-app>
    <Snackbar />
    <ModalIdle v-if="isIdle" />
    <router-view />
  </v-app>
</template>

<script>
import Snackbar from "@/components/ui/Snackbar";
import ModalIdle from "@/components/ui/ModalIdle";
import { get } from "lodash";
import config from "../src/config/instance";

export default {
  name: "App",

  components: {
    Snackbar,
    ModalIdle,
  },

  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle && this.$store.getters.loggedIn;
    },
    isAuthRequired() {
      return this.$route.matched.some((route) =>
        get(route, "meta.requiresAuth", false)
      );
    },
  },
  mounted() {
    let script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    window.fwSettings = {
      widget_id: config.Freshwork.Widget_Id,
      locale: "en",
    };
    !(function () {
      if ("function" != typeof window.FreshworksWidget) {
        var n = function () {
          n.q.push(arguments);
        };
        (n.q = []), (window.FreshworksWidget = n);
      }
    })();
    let myScript = document.createElement("script");
    myScript.setAttribute("type", "text/javascript");
    myScript.setAttribute("src", `${config.Freshwork.Widget_Url}`);
    document.head.appendChild(script);
    document.head.appendChild(myScript);
  },
};
</script>
